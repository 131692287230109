import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import theme from '../style/theme';
import GlobalStyle from '../style/GlobalStyle';
import getIE from '../lib/getIE';

import { IESupport } from '../components/errors';
import Head from './Head';
import Header from './Header';

const NAME_CUTOFF = 300;

class Layout extends Component {
  constructor() {
    super();

    this.state = {
      isIE: false,
      show: false,
      y: 0,
    };
  }

  componentDidMount() {
    this.setState({ isIE: getIE(window) });
    window.addEventListener('scroll', () => {
      const cur = this.getY(document);
      const { y } = this.state;
      this.setState({ show: cur > NAME_CUTOFF && cur < y, y: cur });
    });
  }

  getY = document =>
    document.documentElement.scrollTop || document.body.scrollTop;

  render() {
    const {
      children,
      title,
      description,
      img,
      url,
      path,
      t,
      main,
    } = this.props;

    const { isIE, show, y } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <>
          <GlobalStyle />
          <Head
            title={title}
            description={description}
            img={img}
            url={url}
            path={path}
          />
          <Header show={show} y={y} main={main} />
          {isIE ? <IESupport t={t.errors.ie} /> : children}
        </>
      </ThemeProvider>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  img: PropTypes.string,
  url: PropTypes.string,
  path: PropTypes.string,
  t: PropTypes.object.isRequired,
  main: PropTypes.number,
};

export default Layout;
