import React from 'react';
import PropTypes from 'prop-types';

import { graphql } from 'gatsby';
import { withLocale } from '../lib/locale';

import Layout from '../Layout';
import { NotFound } from '../components/errors';

const Error404 = ({ pageContext: { locales } }) => (
  <Layout short title="404" t={locales.common}>
    <NotFound t={locales.common.errors.notFound} />
  </Layout>
);

Error404.propTypes = {
  pageContext: PropTypes.object.isRequired,
};

export default withLocale(Error404);

export const query = graphql`
  {
    sitePage {
      context {
        locales {
          common {
            contact {
              phone
              mail
            }

            errors {
              ie {
                title
                text
              }

              notFound {
                title
                link
              }
            }
          }
        }
      }
    }
  }
`;
