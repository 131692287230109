import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { getPathFor } from '../../lib/sitemap';
import { LocaleConsumer } from '../../lib/locale';

const LocalizedLink = ({ route, name = '', ...props }) => (
  <LocaleConsumer>
    {({ locale }) => (
      <Link to={getPathFor({ route, name, locale })} {...props} />
    )}
  </LocaleConsumer>
);

LocalizedLink.propTypes = {
  route: PropTypes.string,
  name: PropTypes.string,
};

export default LocalizedLink;
