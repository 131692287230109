import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes, css } from 'styled-components';

import { LocaleConsumer } from '../lib/locale';
import LocalizedLink from '../components/common/LocalizedLink';

const Wrapper = styled.section`
  z-index: 10;
  position: ${({ y }) => (y >= 300 ? 'fixed' : 'absolute')};
  opacity: ${({ y }) => (y >= 80 ? 0 : 1)};
  top: 0;
  left: 0;
  right: 0;
  transition: opacity 0.3s ease-out;
  background: ${({ theme }) => theme.colors.bg};
  padding: 0.5rem 0;

  ${({ show }) =>
    show &&
    css`
      opacity: 1;
    `}
`;

const Content = styled.div`
  padding: 0 2rem;
  margin: 0 auto;
  height: 3rem;
  max-width: ${({ theme }) => theme.maxWidth};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`;

const Close = styled.div`
  display: ${props => !props.open && 'none'};
  position: fixed;
  z-index: 10;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

const Reveal = keyframes`
  from {
    transform: translateY(-10%);
    opacity: 0.2;
  }

  to {
    transform: tranlateY(0);
    opacity: 1;
  }
`;

const LanguageOptions = styled.ul`
  display: ${props => !props.open && 'none'};
  position: absolute;
  top: 2em;
  left: -0.75em;
  text-align: center;
  background: ${({ theme }) => theme.colors.bg};
  font-weight: 700;
  font-size: 0.9rem;
  animation: ${Reveal} 0.2s ease-out;
`;

const Option = styled.li`
  display: ${props => props.active && 'none'};
  cursor: pointer;
  transition: background 0.2s ease-out;
  padding: 0 1rem;
  color: ${({ theme }) => theme.colors.fg};
  text-transform: uppercase;
  font-size: 17px;

  &:hover {
    background: ${({ theme }) => theme.colors.gray};
  }
`;

const Language = styled.button`
  position: relative;
  display: block;
  margin-left: auto;

  width: 35px;
  text-align: center;
  text-transform: uppercase;
  color: ${({ active, theme }) =>
    active ? theme.colors.primary : theme.colors.fg};
  font-weight: 700;
  cursor: pointer;
  transition: color 0.2s ease-out;
  font-size: 18px;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const Name = styled(LocalizedLink)`
  font-family: ${({ theme }) => theme.fonts.secondary};
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.5rem;
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: opacity 0.3s ease-out;
`;

const handleChangeLanguage = (newLang, altern) =>
  (window.location.pathname = altern[newLang]);

const Header = ({ show, y, main }) => {
  const [open, setOpen] = useState(false);

  return (
    <LocaleConsumer>
      {({ locale: lang, altern }) => (
        <>
          <Close onClick={() => setOpen(false)} open={open} />
          <Wrapper show={show} y={y}>
            <Content>
              <Name show={show || !main} route="index">
                Boháček
              </Name>
              <Language onClick={() => setOpen(!open)} active={open}>
                {lang}
                <LanguageOptions open={open}>
                  <Option
                    active={lang === 'cs'}
                    onClick={() => handleChangeLanguage('cs', altern)}
                  >
                    cs
                  </Option>
                  <Option
                    active={lang === 'en'}
                    onClick={() => handleChangeLanguage('en', altern)}
                  >
                    en
                  </Option>
                  <Option
                    active={lang === 'fr'}
                    onClick={() => handleChangeLanguage('fr', altern)}
                  >
                    fr
                  </Option>
                </LanguageOptions>
              </Language>
            </Content>
          </Wrapper>
        </>
      )}
    </LocaleConsumer>
  );
};

Header.propTypes = {
  show: PropTypes.bool,
  y: PropTypes.number,
  main: PropTypes.number,
};

export default Header;
