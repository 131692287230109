import { css } from 'styled-components';

const sizes = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  mobileXL: 500,
  tablet: 768,
  laptop: 1024,
  laptopL: 1600,
  desktopS: 1980,
  desktop: 2560,
};

export default Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${sizes[label] / 18}em) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});
