import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import cover from '../assets/og.png';

const Head = ({
  name = 'Boháček',
  title = 'Web Developer',
  description = 'Tech enthusiast, history nerd, design aficionado. But more importantly a web developer with two year experience designing, coding and managing.',
  url = 'https://bohacek.me',
  img = {
    path: cover,
    width: 1080,
    height: 566,
  },
}) => (
  <Helmet>
    {/* Meta */}
    <meta charSet="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
    <meta name="referrer" content="origin" />

    {/* Titles and stuff */}
    <title>{`${title} | ${name}`}</title>
    <meta name="description" content={description} />
    <meta name="author" content="Jakub Boháček <jamesbohacek@outlook.com>" />

    {/* OG */}
    <meta property="og:url" content={url} />
    <meta property="og:title" content={`${title} | ${name}`} />
    <meta property="og:description" content={description} />
    <meta name="twitter:site" content={url} />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:image" content={img.path} />
    <meta property="og:image" content={img.path} />
    <meta property="og:image:width" content={img.width} />
    <meta property="og:image:height" content={img.height} />
  </Helmet>
);

Head.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string,
  img: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ),
};

export default Head;
