exports.routes = {
  en: {
    path: '',
    index: '/',
  },
  cs: {
    path: '/cs',
    index: '/',
  },
  fr: {
    path: '/fr',
    index: '/',
  },
};

exports.locales = {
  index: ['home', 'projects', 'common'],
  '404.html': ['common'],
  '404': ['common'],
};

exports.getPathFor = ({ route, name, locale }) =>
  exports.routes[locale].path +
  exports.routes[locale][route] +
  (name ? `/${name}` : '');
