import React from 'react';
import PropTypes from 'prop-types';

const localeContext = React.createContext();

export const LocaleProvider = localeContext.Provider;
export const LocaleConsumer = localeContext.Consumer;

export const withLocale = PageComponent =>
  // eslint-disable-next-line react/display-name
  props => {
    // eslint-disable-next-line react/prop-types
    const { pageContext } = props;

    return (
      <LocaleProvider
        value={{
          locale: pageContext.locale,
          altern: JSON.parse(pageContext.altern),
        }}
      >
        <PageComponent {...props} />
      </LocaleProvider>
    );
  };

withLocale.propTypes = {
  pageComponent: PropTypes.element.isRequired,
  props: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};
