import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import LocalizedLink from '../common/LocalizedLink';
import { Heading, A } from '../common/typography';
import { Wrapper, Content } from '../common/structure';

const NFContent = styled(Content)`
  margin-top: 20vh;
`;

const NotFound = ({ t }) => (
  <Wrapper>
    <NFContent>
      <Heading color="primary">{t.title}</Heading>
      <A>
        <LocalizedLink route="index">> {t.link}</LocalizedLink>
      </A>
    </NFContent>
  </Wrapper>
);

NotFound.propTypes = {
  t: PropTypes.object.isRequired,
};

export default NotFound;
