import styled from 'styled-components';

export const Wrapper = styled.section`
  position: relative;
  padding: 5rem 0;
`;

export const Content = styled.div`
  max-width: 90rem;
  margin: 0 auto;
  padding: 0 2rem;
`;
