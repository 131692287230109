module.exports = {
  bg: '#ffffff',
  fg: '#000000',
  primary: '#ff1212',
  primaryL: '#f9918e',
  secondary: '#12ffff',
  analog: '#ff1289',
  analogL: '#fdb9d7',
  transparent: 'rgba(0, 0, 0, 0)',
};
