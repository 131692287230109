import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import media from '../../lib/media';

export const Heading = styled.h2`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 12vw;
  ${media.tablet`
    font-size: ${({ theme }) => theme.fontSizes.heading}em;
  `}
  font-weight: 700;
  text-align: ${({ centre }) => (centre ? 'center' : 'left')};
  margin-bottom: 1rem;
  color: ${({ color, theme }) => theme.colors[color || 'fg']};
  text-transform: uppercase;
`;

export const Title = styled.h1`
  font-size: 15vw;
  ${media.laptop`
    font-size: ${({ theme }) => theme.fontSizes.title}em;
  `}
  line-height: 1;
  font-weight: 800;
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${({ color, theme }) => theme.colors[color || 'fg']};
  margin-bottom: 1rem;
  text-align: ${({ centre }) => (centre ? 'center' : 'left')};
  text-transform: uppercase;
  ${({ shadow }) =>
    shadow &&
    css`
      text-shadow: 5px 5px 7px rgba(0, 0, 0, 0.3);
    `};
`;

export const Subtitle = styled.h3`
  font-size: ${({ theme, caps }) =>
    css`calc(${theme.fontSizes.subtitle}em * ${caps ? 0.9 : 1})`};
  font-weight: 700;
  color: ${({ light, theme, subtle }) =>
    (light && theme.colors.bg) ||
    (subtle && 'rgba(0, 0, 0, 0.5)') ||
    theme.colors.fg};
  text-transform: ${props => props.caps && 'uppercase'};
  text-align: center;
  line-height: 1.2;
  margin-bottom: 1rem;
`;

export const A = styled.span`
  font-weight: 700;
  transition: color 0.2s ease-out;
  color: blue;

  &:hover {
    text-decoration: underline;
  }

  ${props =>
    props.external &&
    css`
      &::before {
        content: '↪ ';
      }
    `}
`;

export const ExternalA = ({ href, children, className }) => (
  <a
    href={href}
    target="_blank"
    rel="noreferrer noopener"
    className={className}
  >
    {children}
  </a>
);

ExternalA.propTypes = {
  children: PropTypes.any.isRequired,
  href: PropTypes.string,
  className: PropTypes.string,
};

export const Strong = styled.strong`
  font-weight: 700;
`;
