import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Md from 'markdown-to-jsx';

import { Heading, A } from '../common/typography';
import { Wrapper, Content } from '../common/structure';

const IEWrapper = styled(Wrapper)`
  padding: 10rem 0;
  min-height: 100vh;
  color: ${props => props.theme.colors.bg};
`;

const IEContent = styled(Content)`
  margin-top: calc(33vh - 5rem);
`;

const IESupport = ({ t }) => (
  <IEWrapper>
    <IEContent>
      <Heading light>{t.title}</Heading>
      <Md
        options={{
          overrides: {
            a: {
              component: A,
              props: {
                as: 'a',
                external: true,
              },
            },
          },
        }}
      >
        {t.text}
      </Md>
    </IEContent>
  </IEWrapper>
);

IESupport.propTypes = {
  t: PropTypes.object.isRequired,
};

export default IESupport;
