import { createGlobalStyle } from 'styled-components';

import lbt from '../assets/fonts/Livvic-Black.ttf';
import lbw from '../assets/fonts/Livvic-Black.woff';
import lbw2 from '../assets/fonts/Livvic-Black.woff2';

/**
 * Based on https://github.com/jtrost/Complete-CSS-Reset
 */
export default createGlobalStyle`
  @font-face {
    font-family: 'Livvic';
    src: local('Livvic Black'), local('Livvic-Black'),
         url(${lbw2}) format('woff2'),
         url(${lbw}) format('woff'),
         url(${lbt}) format('truetype');
    font-weight: 800;
    font-style: normal;
  }

  /* Displays for HTML 5 */
  article, aside, audio, command, datagrid, details, dialog, embed,
  figcaption, figure, footer, header, hgroup, menu, nav, section, summary,
  video, wbr {
    display: block;
  }

  bdi, figcaption, keygen, mark, meter, progress, rp, rt, ruby, time {
    display: inline;
  }

  /* Deprecated tags */
  acronym, applet, big, center, dir, font, frame, frameset, noframes, s,
  strike, tt, u, xmp {
    display: none;
  }

  /* Reset styles for all structural tags */
  a, abbr, area, article, aside, audio, b, bdo, blockquote, body, button,
  canvas, caption, cite, code, col, colgroup, command, datalist, dd, del,
  details, dialog, dfn, div, dl, dt, em, embed, fieldset, figure, form,
  h1, h2, h3, h4, h5, h6, head, header, hgroup, hr, html, i, iframe, img,
  input, ins, keygen, kbd, label, legend, li, map, mark, menu, meter, nav,
  noscript, object, ol, optgroup, option, output, p, param, pre, progress,
  q, rp, rt, ruby, samp, section, select, small, span, strong, sub, sup,
  table, tbody, td, textarea, tfoot, th, thead, time, tr, ul, var, video {
    background: transparent;
    border: 0;
    font-size: 100%;
    font: inherit;
    margin: 0;
    outline: none;
    padding: 0;
    text-align: inherit;
    text-decoration: none;
    vertical-align: baseline;
    z-index: 1;
    box-sizing: border-box;
  }

  /* Miscellaneous resets */
  html {
    font-family: ${props => props.theme.fonts.primary}, sans-serif;
    font-size: ${props => props.theme.rem}px;
    font-weight: 400;
    line-height: ${props => props.theme.lineHeight};
    overflow-x: hidden;
  }

  body {
    font-size: ${props => props.theme.fontSizes.default}rem;
    background-color: ${props => props.theme.colors.bg};
    color: ${props => props.theme.colors.fg};
    letter-spacing: ${props => props.theme.letterSpacing.regular};
    overflow-x: hidden;
  }

  a {
    color: inherit;
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after, q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  button {
    color: inherit;
    cursor: pointer;
  }

  button:disabled {
    cursor: default;
  }

  textarea, input {
    border: none;
    overflow: auto;
    outline: none;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
`;
