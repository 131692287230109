const colors = require('./colors');

const rem = 18;

/**
 * Calculates the em value of a heading of a given order
 * @param {number} order - The order of heading
 * @returns Em value
 */
const getHeadingSize = order => 2 ** order;

module.exports = {
  colors: {
    ...colors,
  },

  // Layout
  maxWidth: '90rem',

  // Typography
  fonts: {
    primary: 'Courier New',
    secondary: 'Livvic',
  },
  rem,
  fontSizes: {
    regular: 1,
    emphasis: getHeadingSize(0),
    subtitle: getHeadingSize(1),
    heading: getHeadingSize(2),
    title: getHeadingSize(3),
  },
  lineHeight: 1.8,
  letterSpacing: {
    regular: '1.3px',
    extended: '3px',
    condensed: '1px',
  },
};
